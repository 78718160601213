<template>
  <div>
    <div class="ma-3">
      <v-btn @click="$router.push({ name: 'PrivacyPolicy' })">
        Privacy Policy
      </v-btn>
    </div>
    <div>
      <v-btn href="mailto:luke.lewitzka@beaker-soft.com">
        CONTACT SUPPORT
      </v-btn>
    </div>
  </div>
</template>

<script>

export default {
};
</script>
